<template>
  <div class="protospelling">
    <div v-if="loading || !category">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="category.concept" :pretitle="'Category details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button @click="deleteItem" v-if="$store.getters.checkRole('legam.sem.category.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.sem.category.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button @click="deleteItem" v-if="!$store.getters.checkRole('legam.sem.category.modify') && $store.getters.checkRole('legam.sem.category.delete')" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Concept</h6>
                  <b-form-input
                    id="concept"
                    v-model="category.concept"
                    placeholder="Concept"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="category.concept"></span>
                </b-col>
                <b-col>
                  <h6>Ontology</h6>
                  <OntologySelect @select="selectOntology" :selectedId="category.ontologyId" v-if="editMode" />
                  <span v-else v-html="category.ontology.name"></span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h6>Code</h6>
                  <b-form-input
                    id="code"
                    v-model="category.code"
                    placeholder="Code"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="category.code"></span>
                </b-col>
                <b-col cols="6">
                  <h6>Full Code</h6>
                  <span v-html="category.fullCode"></span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <h6>Visible</h6>
                  <b-form-checkbox
                    id="visible"
                    v-model="category.visible"
                    value="true"
                    v-if="editMode"
                  >
                  </b-form-checkbox>
                  <span v-else>
                    <span v-if="category.visible">Yes</span>
                    <span v-else>No</span>
                  </span>
                </b-col>
              </b-row>
            </article>
          </b-col>
        </b-row>
        <hr class="mt-4">
        <b-row class="justify-content-md-center mt-4">
          <b-col>
            <h5>Senses</h5>
            <ul>
              <li v-for="sense in category.senses" :key="sense.id">
                <router-link :to="{name: 'Sense', params: {id: sense.id}}" v-html="sense.definition"></router-link>
              </li>
            </ul>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'category'" :objectId="categoryId" :key="logIndex" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  h6 {
    margin-bottom: 2px;
    margin-top: 1rem;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import OntologySelect from '@/components/OntologySelect.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import config from '@/config.js'

  export default {
    name: 'CategoryDetails',
    data(){
      return {
        config: config,
        categoryId: this.$route.params.id,
        loading: true,
        editMode: false,
        logIndex: 0
      }
    },
    components: {
      PageTitle,
      OntologySelect,
      ChangeLogsTable
    },
    computed: {
      ...mapState({
        category: state => state.category.category
      })
    },
    watch: {
      'category'() {
        this.loading = false
      }
    },
    mounted(){
      this.$store.dispatch('loadCategory', this.$route.params.id)
    },
    methods: {
      selectOntology(ontology){
        this.category.ontologyId = ontology.id
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadCategory', this.categoryId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveCategory', this.category).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.$store.dispatch('loadCategory', this.categoryId)
          this.logIndex++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteCategory', this.category).then(() => {
              router.push('/categories')
            })
          }
        })
      }
    }
  }
</script>
