<template>
  <div class="ontologies" v-if="ontologies.length">
    <!-- <Select2 :options="ontologiesOption" @select="selectOntology($event)" :settings="{}" /> -->
    <b-form-select v-model="selected" :options="ontologiesOption"></b-form-select>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // import Select2 from 'v-select2-component'

  export default {
    name: 'OntologySelect',
    props: ['exclude', 'selectedId'],
    // components: {
    //   Select2
    // },
    data(){
      return {
        selected: this.selectedId
      }
    },
    mounted() {
      this.$store.dispatch('loadOntologies')
    },
    computed: {
      ...mapState({
        ontologies: state => state.common.ontologies
      }),
      ontologiesOption(){
        return this.ontologies.map(ontology => {
          return {
            value: ontology.id,
            text: `${ontology.name}`,
            // selected: ontology.id == this.selectedId
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    watch: {
      "selected"(){
        let ontology = null
        for (let _ontology of this.ontologies){
          if (_ontology.id == this.selected){
            ontology = _ontology
            break
          }
        }
        this.$emit('select', ontology)
      }
    },
    // methods: {
    //   selectOntology(event){
    //     let ontology = null
    //     for (let _ontology of this.ontologies){
    //       if (_ontology.id == event.id){
    //         ontology = _ontology
    //         break
    //       }
    //     }
    //     this.$emit('select', ontology)
    //   }
    // }
  }
</script>
